.services-header {
   background-image: none;
  .header__btn-phone, .header__number {
    color: #232323;
  }

   .header__btn-phone, .header__number {
     color: #232323;
   }

  .header__content {
    position: relative;
    margin-bottom: 450px;
  }
 }