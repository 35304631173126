.header {
  background-color: #f8f8f8;
  background-image: url('../img/header_bg.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right calc(50% - 589px) top;
  padding: 15px 0 0;
  position: relative;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 103px;
    position: relative;
  }

  &__nav {
    position: relative;
    top: 10px;
    left: -82px;
  }

  &__nav-item {
    display: inline-block;
    padding: 0 0 26px 0;
    margin-right: 24px;
    font-size: 12px;
    font-weight: 500;
    box-sizing: border-box;
    color: #666;
    border-bottom: 3px solid transparent;
    text-transform: uppercase;

    &:hover {
      color: #232323;
      border-bottom: 3px solid #88C524;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .nav-open {
    width: 30px;
    height: 30px;
    background: url("../img/menu.png") no-repeat center center;
    background-size: contain;
    position: absolute;
    display: none;
    cursor: pointer;
  }

  .nav-close {
    display: none;
  }

  &__number {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #fff;
    position: relative;
    left: -24px;
    top: -3px;
  }

  &__btn-phone {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #fff;
    background: transparent;
    border: 1px solid #b3b3b3;
    padding: 12px 9px 10px;
    position: relative;
    top: -3px;

    &:hover {
      color: #0d0d0d;
      background: #88c524;
      border-color: #88c524;
    }
  }

  &__content {
    display: flex;
    position: relative;
  }

  &__title {
    color: #232323;
    font-family: "Cormorant Garamond", serif;
    font-size: 97px;
    font-weight: 600;
    letter-spacing: 4.85px;
    line-height: .9;
    margin-bottom: 15px;

    span {
      font-family: Geometria;
      display: inline-block;
      margin-bottom: 12px;
      text-transform: uppercase;
      color: #232323;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 1.4px;
    }
  }
  &__left {
    position: relative;
  }
  &__text-slider {
    width: 370px;
    position: relative;

    .header__text-slide {
      width: 100%;
      color: #232323;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      background: transparent;
    }
  }

  &__btn-promo {
    width: 200px;
    height: 55px;
    margin-top: 35px;
    color: #0d0d0d;
    font-size: 14px;
    font-weight: 900;
    line-height: 54px;
    background: #88c524;
    border: none;
    border: 1px solid transparent;
    display: inline-block;
    text-align: center;

    &:hover {
      background: #232323;
      color: #fff;
      border-color: #232323;
    }
  }

  &__photo-slider {
    width: 455px;
    position: absolute;
    top: -90px;
    right: -218px;

    .header__photo-slide {
      width: 100%;

      img {
        max-width: 455px;width: 100%;
      }
    }
  }
  .header-next-slide, .header-prev-slide {
    width: 31px;
    height: 12px;
    background: url("../img/slider_arrow.svg") no-repeat center center;
    cursor: pointer;
    position: absolute;
    top: 264px;
    left: 280px;

    &:hover {
      background: url("../img/slider_arrow-hover.svg") no-repeat 0 center;
      transform: rotate(180deg);
    }
  }
  .header-next-slide{
    transform: rotate(180deg);
    top: 264px;
    left: 340px;

    &:hover {
      transform: rotate(0deg);
    }
  }
  .header__pagination {
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    top: 450px;
    left: 202px;
  }

  &__down {
    color: #0d0d0d;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    position: absolute;
    transform: rotate(-90deg);
    top: 405px;
    left: -92px;

    &:hover {
      color: #666;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 9px;
      background: url("../img/down_arr.svg");
      transform: rotate(90deg);
      margin-right: 15px;
    }
  }

  &__nav-phone {
    display: none;
  }
}
//-------------------------------MEDIA
@media screen and (max-width: 1199px){
  .header {
    background-position: right calc(50% - 552px) top;
    &__nav {
      left: -10px;
    }

    &__photo-slider {
      right: -173px;
    }
    &__down {
      left: -50px;
    }
  }
}

@media screen and (max-width: 1023px){
  .header {
    background-position: right calc(50% - 282px) top;
    padding-bottom: 40px;

    &__title {
      font-size: 67px;
    }
    &__top {
      margin-bottom: 0;
    }
    &__left {
      padding-left: 20px;
    }

    &__photo-slider {
      width: 286px;
      right: -10px;
      top: 30px;

      .header__photo-slide {

        img {
          width: 100%;
        }
      }
    }
    .header-next-slide, .header-prev-slide {
      top: 245px;
      left: 170px;
    }
    .header-next-slide{
      top: 245px;
      left: 230px;
    }
    .header__pagination {
      top: 380px;
      left: 130px;
    }
    &__down {
      top: 330px;
      left: -57px;
    }
    .nav-open {
      display: block;
      left: 375px;
      top: 0;
    }
    &__nav.showed {
      left: 0;
    }
    &__nav {
      position: fixed;
      top: 0;
      left: -100%;
      background: rgba(0, 0, 0, .95);
      max-width: 250px;
      width: 100%;
      height: 100vh;
      z-index: 10;
      padding: 30px 0 0 40px;
      transition: .5s ease;
    }

    .nav-close {
      display: block;
      position: absolute;
      top: 20px;
      right: 15px;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 3px;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(45deg);
      }
      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 3px;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(-45deg);
      }
    }
    &__nav-item {
      display: block;
      margin-right: 0;
      margin-bottom: 25px;
      padding: 0;
      font-size: 20px;
      font-weight: 500;
      box-sizing: border-box;
      border: none;
      color: #fff;
      text-transform: uppercase;

      &:hover {
        border: none;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .header {
    background: none;
    &__left {
      padding-left: 0;
    }


    &__phone {
      display: none;
    }

    .nav-open {
      top: -7px;
      left: 95%;
    }

    &__photo-slider {
      top: 20px;
      right: 37px;
    }

    .header-next-slide, .header-prev-slide {
      left: 115px;
      top: 240px;
    }
    .header-next-slide{
      left: 165px;
      top: 240px;
    }
    .header__pagination {
      top: 375px;
      left: 65px;
    }
    &__nav-phone {
      display: block;

      .header__number {
        position: static;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

}

@media screen and (max-width: 639px){
  .header {
    padding-bottom: 50px;
    &__title  {
      font-size: 54px;
    }
    &__down {
      display: none;
    }

    &__photo-slider {
      width: 200px;
      right: 25px;
      top: 29px;
    }

    &__text-slider {
      width: 290px;
    }

    &__pagination {
      top: 295px !important;
      left: 5px !important;
    }

    .header-next-slide, .header-prev-slide {
      left: 55px;
      top: 181px;
    }
    .header-next-slide{
      left: 105px;
      top: 180px;
    }
  }
}

@media screen and (max-width: 479px){
  .header {
    padding-bottom: 25px;
    &__content {
      flex-direction: column;
      text-align: center;
    }

    .nav-open {
      left: 90%;
    }

    &__photo-slider {
      position: absolute !important;
      top: 210px;
      left: 5px;
      width: 290px;
    }

    &__btn-promo {
      margin-top: 435px;
    }

    &__title {
      margin-bottom: 5px;

      span {
        margin-bottom: 0;
      }
    }

    &__pagination {
      left: 132px !important;
      top: 177px !important;
    }

    .header-next-slide, .header-prev-slide {
      left: 85px;
      top: 80px;
    }
    .header-next-slide{
      left: 175px;
      top: 80px;
    }
  }
}