.works {
    background: #F8F8F8;
    padding-top: 132px;
    padding-bottom: 169px;
    .wrapper {
        position: relative;
        &::before {
            content:'';
            display: block;
            height: 1px;
            width: 2000px;
            position: absolute;
            top: 30px;
            left: calc(50% - 2611px);
            background: #88C524;
        }
    }
    &__top {
        display: flex;
        position: relative;
        padding-bottom: 579px;
    }
    .title {
        font-size: 40px;
        margin-top: 6px;
    }
    &__list {
        display: flex;
        margin-left: 74px;
        position: relative;
    }
    &__item {
        margin-left: 10px;
        input {
            visibility: hidden;
            &:checked ~ .works__gallery {
                opacity: 1;
            }
            &:checked ~ .works__label {
                color: #232323;
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
            }
        }
    }
    &__gallery {
        position: absolute;
        left: -298px;
        top: 93px;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1170px;
        transition: .5s;
        opacity: 0;
        display: flex;
        align-items: flex-start;
        img {
            margin-top: 10px;
            width: 383px;
        }
    }
    &__label {
        text-transform: uppercase;
        color: #BBBBBB;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 31px;
        width: 100px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    &__btn {
        width: 170px;
        height: 55px;
        border: 1px solid #6B6B6B;
        background: transparent;
        font-size: 14px;
        color: #232323;
        font-weight: 700;
        display: block;
        margin: 0 auto;
        &:hover {
            background: #88C524;
            border-color: transparent;
            color: #fff;
        }
    }
}




@media screen and (max-width: 1199px){
    .works {
        padding: 100px 0;
        .wrapper {
            &::before {
                left: calc(50% - 2528px);
            }
        }
        &__gallery {
            width: 1004px;
            left: -302px;
            img {
                width: 326px;
            }
        }
        &__top {
            padding-bottom: 510px;
        }
    }
}

@media screen and (max-width: 1023px){
    .works {
        padding: 60px 0;
        .wrapper {
            &::before {
                display: none;
            }
        }
        &__gallery {
            width: 748px;
            left: -279px;
            img {
                width: 368px;
            }
        }
        &__top {
            padding-bottom: 805px;
        }
    }
}

@media screen and (max-width: 767px){
    .works {
        padding: 40px 0;
        &__top {
            flex-direction: column;
            align-items: center;
            padding-bottom: 685px;
        }
        .title {
            margin-top: 0;
        }
        &__list {
            margin-left: 0;
        }
        &__item {
            margin: 0 5px;
        }
        &__gallery {
            width: 620px;
            left: -70px;
            img {
                width: 304px;
            }
        }
    }
}

@media screen and (max-width: 639px){
    .works {
        &__top {
            padding-bottom: 1520px;
        }
        &__gallery {
            width: 440px;
            left: 20px;
            justify-content: center;
            img {
                width: 350px;
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 479px){
    .works {
        padding: 25px 0;
        &__item {
            input {
                position: relative;
                top: 27px;
            }
        }
        &__gallery {
            width: 300px;
            left: 0px;
            top: 120px;
            img {
                width: 300px;
            }
        }
        &__list {
            flex-wrap: wrap;
            justify-content: center;
        }
        &__top {
            padding-bottom: 1300px;
        }
    }
}
