.director {
  padding: 242px 0 140px;
  background: #232323;

  .wrapper {
    display: flex;
    position: relative;
    padding: 0 100px;

    &::before {
      content: 'Okinawa';
      display: block;
      font-family: 'Cormorant Garamond', serif;
      font-style: normal;
      font-weight: bold;
      font-size: 200px;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.1em;
      color: #2F2F2F;
      position: absolute;
      left: 160px;
      top: -145px;
      z-index: 0;
    }

    &::after {
      content: '';
      display: block;
      width: 122px;
      height: 360px;
      background: url("../img/director-bg.png") no-repeat;
      position: absolute;
      left: -55px;
      top: 143px;
    }
  }

  &__left {
    position: relative;
    z-index: 1;
  }

  &__title {
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 40px;
    line-height: 42px;
    color: #78B01E;
    margin-bottom: 10px;
  }

  &__subtitle {
    margin: 0;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 30px;
  }

  &__descr {
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-bottom: 20px;
  }

  .quotes {
    position: relative;

    &::before, &::after {
      content: '';
      display: block;
      width: 10px;
      height: 9px;
      position: absolute;
      top: -15px;
      left: 98px;
      background: url("../img/about-quotes.png") no-repeat;
    }
    &::after {
      transform: rotate(180deg);
      top: 330px;
      left: 260px;
    }
  }
  &__right {
    position: relative;
    padding: 54px 60px 0 32px;

    &::before {
      content: '';
      display: block;
      width: 80px;
      height: 70px;
      background: url("../img/director-sign.svg") no-repeat;
      position: absolute;
      bottom: 62px;
      left: 23px;
    }
    &::after {
      content: '';
      display: block;
      width: 161px;
      height: 1px;
      background: #88C524;
      position: absolute;
      bottom: 93px;
      left: 130px;
    }
  }
}

@media screen and (max-width: 1199px){
  .director {
    padding: 100px 0;

    .wrapper {

      &::before {
        font-size: 140px;
        left: 240px;
        top: -115px;
      }

      &::after {
        left: -30px;
      }
    }

    &__left {

      .director__img {
        width: 380px;

        img {
          width: 100%;
        }
      }
    }

    &__right {
      padding: 0 0 0 30px;

      &::before {
        bottom: 10px;
      }
      &::after {
        bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .director {
    padding-bottom: 60px;
    .wrapper {
      padding: 0 10px;

      &::before {
        left: 90px;
      }
    }

    .quotes {

      &::before, &::after {
        display: none;
      }
    }
    &__subtitle {
      margin-bottom: 10px;
    }
    &__right {
      padding: 0 0 0 15px;

      &::before {
        bottom: -40px;
        left: 10px;
      }
      &::after {
        bottom: -10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .director {

    .wrapper {
      &::before {
        left: 25px;
      }
    }

    &__left {

      .director__img {
        width: 240px;
      }
    }
  }
}


@media screen and (max-width: 639px) {
  .director {

    .wrapper {
      flex-direction: column;

      &::before {
        font-size: 113px;
        left: 0;
      }
    }

    &__left {

      .director__img {
        width: 80%;
        margin: 0 auto;
      }
    }
    &__right {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      &::before {
        left: 144px;
      }
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .director {
    padding-top: 80px;

    .wrapper {
      flex-direction: column;

      &::before {
        font-size: 73px;
        left: 8px;
        top: -80px;
      }
    }

    &__left {

      .director__img {
        width: 100%;
      }
    }
    &__right {
      width: 100%;
      &::before {
        left: 112px;
      }
    }
  }
}
