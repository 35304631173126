.about {
    padding-top: 66px;
    color: #232323;
    overflow: hidden;
    padding-bottom: 117px;
    .title {
        font-size: 48px;
        color: #232323;
        width: 800px;
        text-align: left;
        margin: 70px auto 0;
        line-height: 1;
    }
    &__page-name {
        font-size: 40px;
        font-weight: 700;
        font-family: 'Cormorant Garamond', serif;
        position: relative;
        &::before {
            position: absolute;
            content: '';
            height: 1px;
            width: 2000px;
            top: 23px;
            left: -2032px;
            background: #88C524;
        }
    }
    &__article {
        padding-left: 201px;
        padding-top: 86px;
        line-height: 1.6;
        color: #232323;
    }
    &__text {
        font-size: 14px;
        display: flex;
    }
    &__text1 {
        padding-bottom: 457px;
        .about__text {
            width: 330px;
        }
        img {
            left: 140px;
        }
    }
    &__text-subtitle {
        font-size: 24px;
        font-family: 'Cormorant Garamond', serif;
    }
    &__text2 {
        padding-bottom: 144px;
        padding-left: 472px;
        .about__text2-box {
            width: 390px;
        }
        .about__text {
            margin-top: 10px;
        }
        img {
            left: -601px;
            top: -297px;
        }
    }
    &__subbox {
        margin-top: 12px;
        & p:first-child {
            margin-bottom: 22px;
        }
    }
    &__text3 {
        padding-bottom: 90px;
        .about__text2-box {
            width: 370px;
        }
        img {
            left: 99px;
            top: -50px;
        }
    }
    &__img {
        position: relative;
        img {
            position: absolute;
        }
    }
    &__insert {
        font-size: 24px;
        font-style: italic;
        font-family: 'Cormorant Garamond', serif;
        color: #232323;
        width: 370px;
        margin-left: 199px;
        margin-top: 29px;
        position: relative;
        &::before {
            height: 1px;
            width: 170px;
            background: #CA507B;
            content:'';
            position: absolute;
            left: -199px;
            top: 13px;
        }
    }
    &__quotes {
        &::before,
        &::after {
            content:'';
            background-position: center center;
            width: 10px;
            height: 10px;
            position: absolute;
        }
        &::before {
            background-image: url(../img/about-quotes1.png);
            top: -14px;
            left: 134px;
        }
        &::after {
            background-image: url(../img/about-quotes2.png);
            bottom: -10px;
            left: 231px;
        }
    }
    &__text-item {
        width: 370px;
        line-height: 1.6;
    }
    &__list-img {
        position: relative;
        img {
            position: absolute;
            left: 30px;
            top: -49px;
        }
    }
    &__list {
        list-style: disc;
        font-weight: 700;
        margin-top: 27px;
        padding-left: 19px;
        li {
            margin-bottom: 6px;
        }
    }
    &__list-box {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 136px;
        font-size: 14px;
        padding-left: 200px;
        padding-bottom: 65px;
    }
}
//-------------------------------MEDIA
@media screen and (max-width: 1199px){
    .about {
        &__article {
            padding-left: 104px;
        }
        &__text2 {
            img {
                left: -576px;
            }
        }
        &__list-box {
            padding-left: 65px;
        }
        &__list-img {
            img {
                width: 538px;
                top: 0;
            }
        }
    }
}

@media screen and (max-width: 1023px){
    .about {
        padding-top: 40px;
        padding-bottom: 40px;
        .title {
            width: 100%;
            margin-top: 40px;
        }
        &__article {
            padding-left: 0;
            padding-top: 40px;
        }
        &__img {
            img {
                width: 310px;
            }
        }
        &__text1 {
            padding-bottom: 275px;
            img {
                left: 108px;
            }
        }
        &__text2 {
            padding-left: 375px;
            padding-bottom: 30px;
            img {
                left: -375px;
                top: -65px;
            }
        }
        &__text3 {
            padding-bottom: 40px;
            img {
                left: 68px;
                top: 17px;
            }
        }
        &__list-box {
            margin-top: 60px;
            padding-left: 0;
            padding-bottom: 40px;
        }
        &__text-item {
            width: 100%;
        }
        &__list-img {
            img {
                width: 378px;
                top: 67px;
                left: auto;
                right: 0;
            }
        }
    }
}


@media screen and (max-width: 767px){
    .about {
        &__article {
            line-height: 1.4;
        }
        &__img {
            img {
                width: 255px;
            }
        }
        &__text1 {
            padding-bottom: 190px;
            img {
                left: 35px;
            }
        }
        &__subbox {
            p:first-child {
                margin-bottom: 10px;
            }
        }
        &__text-subtitle {
            line-height: 1.1;
        }
        &__text2 {
            padding-left: 300px;
            img {
                top: 9px;
                left: -300px;
            }
        }
        &__text3 {
            .about__text2-box {
                width: 330px;
            }
            img {
                left: 35px;
            }
        }
        &__list-img {
            img {
                width: 338px;
                top: 107px;
            }
        }
        &__list-box {
            padding-bottom: 30px;
        }
    }
}

@media screen and (max-width: 639px){
    .about {
        padding-top: 30px;
        padding-bottom: 30px;
        &__article {
            padding-top: 0;
        }
        &__text1 {
            padding-bottom: 0;
            .about__text {
                width: 100%;
            }
        }
        &__img {
            margin-top: 15px;
            img {
                position: static;
            }
        }
        &__text {
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
        }
        &__text2 {
            padding-bottom: 0;
            padding-left: 0;
            .about__img {
                order: 1;
            }
            .about__text2-box {
                width: 100%;
            }
        }
        &__text3 {
            padding-bottom: 10px;
            .about__text2-box {
                width: 100%;
            }
        }
        &__insert {
            margin-left: auto;
            margin-right: auto;
            &::before {
                display: none;
            }
        }
        &__list-box {
            margin-top: 30px;
            flex-direction: column;
            align-items: center;
            padding-bottom: 0;
        }
        &__list {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        &__list-img {
            img {
                position: static;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 479px){
    .about {
        padding-top: 25px;
        padding-bottom: 25px;
        &__img {
            img {
                width: 100%;
            }
        }
        &__insert {
            width: 100%;
            margin-top: 15px;
        }
        &__quotes {
            &::before, &::after {
                display: none;
            }
        }
    }
}