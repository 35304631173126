.team {
  padding: 78px 0;

  .title {
    text-align: left;
    margin: 60px 0 253px 72px;
    font-size: 40px;
    line-height: 42px;
  }
  .wrapper {
    display: flex;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 1000px;
      height: 1px;
      background: #88C524;
      position: absolute;
      top: 84px;
      left: -1030px;
    }
  }
  &__left {
    width: 445px;
    height: 600px;
    border: 3px solid #CA507B;
    position: relative;
    left: -75px;
    z-index: 0;
  }

  &__btn {
    width: 200px;
    height: 55px;
    border: 1px solid #6B6B6B;
    background: transparent;
    font-size: 14px;
    color: #232323;
    font-weight: 700;
    margin-left: 73px;
    display: inline-block;
    text-align: center;
    line-height: 55px;
    cursor: pointer;
    &:hover {
      border-color: transparent;
      color: #232323;
      background: #88C524;
    }
  }
  &__right {
    width: 1520px;
    position: absolute !important;
    left: 300px;
    top: 72px;
    z-index: 1;
  }
  &__slide {
    margin-right: 30px;
    cursor: pointer;
  }
  &__name {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    font-size: 26px;
    font-style: italic;
    color: #88C524;
    margin: 23px 0 7px;
  }
  &__prof {
    font-size: 12px;
    font-weight: 500;
    color: #232323;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }
  .team-next-slide, .team-prev-slide {
    width: 31px;
    height: 11px;
    background: url("../img/slider_arrow.svg") no-repeat center center;
    cursor: pointer;
    position: absolute;
    top: 166px;
    left: -240px;
    transform: rotate(180deg);

    &:hover {
      background: url("../img/slider_arrow-hover.svg") no-repeat 0 center;
      transform: rotate(0);
    }
  }
  .team-prev-slide {
    transform: rotate(0);
    top: 166px;
    left: -300px;

    &:hover {
      background: url("../img/slider_arrow-hover.svg") no-repeat 0 center;
      transform: rotate(180deg);
    }
  }
}

//-------------------------------MEDIA
@media screen and (max-width: 1199px){
  .team {
    padding: 60px 0;
    .team-next-slide, .team-prev-slide {
      top: 166px;
      left: -220px;
    }
    .team-prev-slide {
      top: 166px;
      left: -280px;
    }
  }
}

@media screen and (max-width: 1023px){
  .team {
    padding: 40px 0;
    .wrapper {
      &::before {
        display: none;
      }
    }
    .title {
      margin: 60px 0 253px 22px;
    }
    &__left {
      width: 350px;
      left: 5px;
      height: 550px;
    }
    &__btn {
      margin-left: 23px;
    }
    .team-next-slide, .team-prev-slide {
      top: 166px;
      left: -190px;
    }
    .team-prev-slide {
      top: 166px;
      left: -250px;
    }
  }
}

@media screen and (max-width: 767px){
  .team {
    .title {
      margin: 0 0 495px 0;
      text-align: center;
    }
    &__left {
      width: 100%;
      height: 620px;
    }
    &__btn {
      display: block;
      margin: 0 auto;
    }
    &__right {
      text-align: center;
      width: 570px;
      left: 40px;
      top: 62px;
    }
    &__name {
      margin: 4px 0;
    }

    .team-next-slide, .team-prev-slide {
      top: 455px;
      left: 300px;
    }
    .team-prev-slide {
      top: 454px;
      left: 240px;
    }
  }
}

@media screen and (max-width: 639px){
  .team {
    padding: 30px 0;
    &__left {
      border: none;
    }

    &__right {
      width: 270px;
      left: 115px;
    }
    .team-next-slide, .team-prev-slide {
      top: 185px;
      left: 300px;
    }
    .team-prev-slide {
      top: 185px;
      left: -60px;
    }
  }
}

@media screen and (max-width: 479px){
  .team {
    padding: 25px 0;
    .title {
      line-height: 1;
    }
    .team-next-slide, .team-prev-slide {
      top: 457px;
      left: 207px;
    }
    .team-prev-slide {
      top: 456px;
      left: 38px;
    }
    &__right {
      left: 25px;
      top: 70px;
    }
  }
}