.reviews {
  padding: 108px 0 102px 197px;

  .wrapper {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 1000px;
      height: 1px;
      background: #88C524;
      position: absolute;
      top: 23px;
      left: -1020px;
    }
  }

  .title {
    text-align: left;
    font-size: 40px;
    margin-bottom: 40px;
  }

  &__btns {
    position: relative;
    top: -95px;
    left: 471px;
  }

  &__btn {
    width: 170px;
    height: 55px;
    text-align: center;
    line-height: 55px;
    border: 1px solid #6B6B6B;
    background: transparent;
    font-size: 14px;
    color: #232323;
    font-weight: 700;
    margin-right: 26px;
    display: inline-block;

    &:hover {
      border-color: transparent;
      color: #fff;
      background: #88C524;
     }

    &-dark {
      background: #232323;
      color: #fff;
      border-color: #232323;

      &:hover {
        border-color: transparent;
        color: #232323;
        background: #88C524;
      }
    }
  }

  &__sliders {
    display: flex;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 131px;
      height: 358px;
      background: url("../img/insta-bg.png");
      position: absolute;
      top: 47px;
      right: 90px;
    }
  }

  &__text-absolute {
    color: #d9d9d9;
    font-size: 110px;
    font-weight: 800;
    letter-spacing: 11px;
    text-transform: uppercase;
    position: absolute;
    left: -296px;
    top: 180px;
    transform: rotate(-90deg);
  }

  &__photo-slider {
    width: 400px;
    margin-right: 70px;

    &__slide {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  &__text-slider {
    padding-top: 37px;
    width: 405px;
  }

  &__slide-title {
    color: #232323;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 1.4px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background: url("../img/reviews-inst.png");
      position: absolute;
      right: 5px;
      top: 4px;
    }
  }

  &__slide-date {
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #88c524;
    padding-bottom: 24px;
    margin-bottom: 35px;
    letter-spacing: 0.36px;
  }

  &__slide-descr {
    color: #232323;
    font-family: "Cormorant Garamond", serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.54px;
    line-height: 26px;
  }


  .reviews-next-slide, .reviews-prev-slide {
    width: 31px;
    height: 11px;
    background: url("../img/slider_arrow.svg") no-repeat center center;
    cursor: pointer;
    position: absolute;
    top: -64px;
    left: 560px;
    transform: rotate(180deg);

    &:hover {
      background: url("../img/slider_arrow-hover.svg") no-repeat 0 center;
      transform: rotate(0);
    }
  }
  .reviews-prev-slide {
    transform: rotate(0);
    top: -64px;
    left: 510px;

    &:hover {
      background: url("../img/slider_arrow-hover.svg") no-repeat 0 center;
      transform: rotate(180deg);
    }
  }

  &__pagination {
    display: inline-block;
    position: absolute;
    font-size: 14px;
    top: 19px;
    right: 230px;
  }

}

//-------------------------------MEDIA
@media screen and (max-width: 1199px){
  .reviews {
    padding: 40px 0 0;

    .wrapper {

      &::before {
        left: -100%;
      }
    }

    &__photo-slider {
      padding-left: 50px;
      width: 360px;
      margin-right: 40px;

      .reviews__photo-slide {

        img {
          width: 100%;
          max-width: 360px;
        }
      }
    }

    &__text-absolute {
      left: -185px;
      font-size: 90px;
      letter-spacing: 1px;
      top: 135px;
    }

    &__text-slider {
      width: 435px;
    }

    &__sliders {

      &::before {
        top: 27px;
        right: 30px;
      }
    }

    &__btns {
      top: -55px;
      left: 400px;
    }
    .reviews-next-slide {
      left: 470px;
    }

    .reviews-prev-slide {
      left: 420px;
    }
  }
}

@media screen and (max-width: 1023px){
  .reviews {
    padding: 30px 0 0;
    .title {
      margin-bottom: 20px;
    }

    .wrapper {

      &::before {
        display: none;
      }
    }

    &__text-absolute {
      display: none;
    }

    &__photo-slider {
      padding-left: 0;
      width: 330px;
      margin-right: 20px;

      .reviews__photo-slide {

        img {
          width: 100%;
          max-width: 330px;
        }
      }
    }

    &__text-slider {
      padding-top: 20px;
      width: 390px;
    }

    &__btns {
      left: 350px;
    }
    &__sliders {

      &::before {
        display: none;
      }
    }

    .reviews-next-slide {
      top: -42px;
      left: 360px;
    }

    .reviews-prev-slide {
      top: -42px;
      left: 310px;
    }

    &__pagination {
      right: 130px;
    }
  }
}

@media screen and (max-width: 767px){
  .reviews {
    padding: 25px 0;

    .title {
      margin-bottom: 10px;
      font-size: 38px;
    }

    &__photo-slider {
      padding-left: 0;
      width: 270px;
      margin-right: 20px;

      .reviews__photo-slide {

        img {
          width: 100%;
          max-width: 270px;
        }
      }
    }

    &__text-slider {
      padding-top: 0;
      width: 320px;
    }

    &__btns {
      position: static;
      margin-top: 20px;
    }
    &__sliders {

      &::before {
        display: none;
      }
    }

    .reviews-next-slide {
      top: 326px;
      left: 110px;
    }

    .reviews-prev-slide {
      top: 326px;
      left: 60px;
    }

    &__pagination {
      right: 297px;
      top: 373px;
    }

    &__slide-date {
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 639px){
  .reviews {

    &__photo-slider {
      padding-left: 0;
      width: 150px;
      margin-right: 20px;

      .reviews__photo-slide {
        padding-top: 10px;
        img {
          width: 100%;
          max-width: 150px;
        }
      }
    }
    &__slide-title {
      margin-bottom: 5px;
    }

    &__slide-descr {
      line-height: 1.1;
      letter-spacing: .1px;
    }

    &__text-slider {
      padding-top: 0;
      width: 280px;
    }

    &__btns {
      position: static;
      margin-top: 20px;
      text-align: center;

      .reviews__btn:last-child {
        margin-right: 0;
      }
    }
    &__sliders {

      &::before {
        display: none;
      }
    }

    .reviews-next-slide {
      top: 226px;
      left: -60px;
    }

    .reviews-prev-slide {
      top: 226px;
      left: -110px;
    }

    &__pagination {
      right: 419px;
      top: 271px;
    }

    &__slide-date {
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 479px){
  .reviews {
    .title {
      margin-bottom: 0;
    }
    &__sliders {
      flex-direction: column;
    }

    &__photo-slider {
      width: 100%;
      margin: 0 auto 10px;
      padding-top: 0;
      .reviews__photo-slide {
        img {
          width: 100%;
          max-width: 320px;
        }
      }
    }

    &__text-slider {
      width: 240px;
      margin: 0 auto;
      text-align: center;
    }
    &__slide-title {

      &::before {
        display: none;
      }
    }
    .reviews-next-slide {
      top: 12px;
      left: 236px;
    }

    .reviews-prev-slide {
      top: 12px;
      left: -29px;
    }


    &__btn {
      width: 144px;

      &:first-child {
        margin-right: 7px;
      }
    }
    .header__pagination {
      display: none;
    }
  }
}