.staff {
  padding: 150px 0 90px 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 110px;
    height: 42px;
    background: url("../img/staff-top.svg");
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    width: 770px;
    margin: 0 auto;
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #232323;
    position: relative;
    margin-bottom: 50px;

    &::before, &::after {
      content: '';
      display: block;
      width: 905px;
      height: 1px;
      background: #CA507B;
      position: absolute;
      top: 20px;
      left: -925px;
    }
    &::after {
      left: 790px;
    }
  }

  &__name {
    font-family: 'Cormorant Garamond', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 26px;
    line-height: 68px;
    color: #88C524;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    display: block;
    margin-bottom: 55px;
    transition: .5s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: 1199px){
  .staff {
    padding: 80px 0 0 0;

    &::before {
      top: 20px;
    }

    &__title {
      text-align: center;
      margin: 0 auto 20px;

      &::before, &::after {
        display: none;
      }
    }

    &__item {
      margin-bottom: 20px;
    }

    &__content {
      justify-content: space-around;
    }
  }
}

@media screen and (max-width: 1023px) {
  .staff {
    padding: 40px 0;
    &::before {
      display: none;
    }

    &__title {
      width: auto;
      br {
        display: none;
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .staff {
    padding: 30px 0;


    &__title {
      width: auto;
      font-size: 24px;
      line-height: 1.1;
    }
  }
}