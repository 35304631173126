.services-main {
    color: #232323;
    padding-bottom: 100px;
    .wrapper {
        position: relative;
    }
    &__back-link {
        position: absolute;
        top: 130px;
        left: 7px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-weight: 700;
        color: #6F6F6F;
        display: flex;
        z-index: 1;
        .services-main__back2 {
            display: none;
        }
        &:hover {
            color: #88C524;
            .services-main__back2 {
                display: block;
            }
            .services-main__back1 {
                display: none;
            }
        }
        img {
            margin-right: 29px;
        }
    }
    .header__btn-promo {
        text-align: center;
        cursor: pointer;
        margin-left: 74px;
        margin-top: 20px;
    }
    &__content {
        padding-left: 556px;
        padding-top: 117px;
    }
    &__title {
        font-family: 'Cormorant Garamond', serif;
        font-size: 40px;
        letter-spacing: 0.03em;
        width: 100%;
        z-index: 1;
    }
    &__price {
        font-size: 40px;
        font-style: italic;
        font-weight: 700;
        font-family: 'Cormorant Garamond', serif;
        margin-top: 20px;
        z-index: 1;
    }
    &__name {
        display: flex;
        flex-wrap: wrap;
    }
    &__pic {
        position: fixed;
        top: 80px;
        left: calc(50% - 377px);
        transition: .1s;
    }
    &__text {
        font-size: 14px;
    }
    &__subname {
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 14px;
    }
    &__par {
        margin-top: 21px;
        line-height: 22px;
    }
    &__text_first {
        width: 370px;
        margin-top: 76px;
        margin-left: 51px;
        position: relative;
        border-bottom: 1px solid #6F6F6F;
        padding-bottom: 14px;
        &::after {
            content:'';
            width: 1px;
            height: 467px;
            background: #88C524;
            position: absolute;
            left: -43px;
            top: -46px;
            z-index: 1;
        }
    }
    &__text_kinds {
        width: 420px;
        margin-top: 35px;
        padding-left: 52px;
        line-height: 22px;
    }
    &__kind-item {
        display: flex;
        margin-top: 15px;
        padding-bottom: 14px;
        border-bottom: 1px solid #E7E7E7;
        .services-main__par {
            margin-top: 0;
        }
        &_last {
            border-color: #6F6F6F;
        }
    }
    &__kind-name {
        color: #C4C4C4;
        min-width: 100px;
    }
}

@media (max-width: 1199px){

    .services-main {
        padding-bottom: 40px;
        &__pic {
            position: fixed;
            top: 80px;
            left: calc(50% - 260px);
            transition: .1s;
            img {
                width: 300px;
            }
        }
        &__back-link {
            left: 10px;
        }
        &__content {
            padding-left: 520px;
        }
    }
}

@media (max-width: 1023px){
    .services-main {

        &__back-link {
            top: 30px;
        }
        &__content {
            padding-left: 260px;
        }
        &__pic {
            left: calc(50% - 350px);
            top: 190px;
            width: 260px;
            img {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 767px){
    .services-main {
        padding-bottom: 30px;
        &__pic {
            width: 250px;
            left: calc(50% - 310px);
        }
        &__content {
            padding-top: 80px;
        }
        &__text_first {
            width: 100%;
            padding: 0 10px 10px;
            margin: 50px 0 0;
            &::after {
                left: -20px;
            }
        }
        &__text_kinds {
            width: 100%;
            padding: 0 10px;
        }
        &__pic {
            top: 170px;
        }
    }
}

@media (max-width: 639px){
    .services-main {
        padding-bottom: 25px;
        .wrapper {
            flex-direction: column;
            align-items: center;
            display: flex;
            padding-top: 40px;
        }
        &__content {
            padding-left: 0;
            padding-top: 20px;
        }
        &__pic {
            position: static;
        }
        &__back-link {
            top: 0;
        }
        &__text_first {
            margin-top: 20px;
            padding: 0 0 10px;
            &::after {
                display: none;
            }
        }
        &__text_kinds {
            padding: 0;
        }
        &__name {
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 32px;
            line-height: 1;
            text-align: center;
        }
        &__price {
            margin-top: 0;
        }
        .header__btn-promo {
            margin-left: 0;
        }
    }
    .extra-wrapper {
        .services-header {
            padding-bottom: 15px;
        }
    }
}
@media (max-width: 479px){

}

